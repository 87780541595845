import { useAuthenticationStatus, useUserId } from "@nhost/react";
import type { PropsWithChildren } from "react";
import { Navigate } from "react-router";
import {
  ErrorComponent,
  NotPermitted,
} from "../common/layout/errorPages/ErrorComponent";
import { useMeQuery, User_Permission_Role_Enum } from "../lib";
import { LoadingScreen } from "../theme/components/LoadingScreen";
import { PATH_AUTH } from "../theme/routes/paths";

export function GuardAdmin({ children }: PropsWithChildren) {
  console.log("render GuardAdmin");
  const { isLoading, isAuthenticated } = useAuthenticationStatus();
  const userId = useUserId();
  const {
    isLoading: userLoading,
    error,
    data,
  } = useMeQuery(
    {
      id: userId,
    },
    {
      enabled: !!userId,
    }
  );
  const hasAdminPermission = data?.user?.user_permissions?.find(
    (p) => p.role === User_Permission_Role_Enum.Admin
  );

  if (isLoading || userLoading) {
    return <LoadingScreen />;
  }
  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.login} />;
  }
  if (error) {
    return <ErrorComponent error={error} />;
  }
  if (!hasAdminPermission) {
    return <NotPermitted />;
  }
  return children;
}

import {DatePickerElement, DatePickerElementProps} from "react-hook-form-mui/date-pickers";
import {FieldValues} from "react-hook-form";
import {localDateToUtc, utcDatetimeToLocal} from "./dateTimeHelper";

export function DatePickerFeg<T extends FieldValues>({
                                                         ...props
                                                     }: DatePickerElementProps<T>) {
    return (
        <DatePickerElement {...props} transform={{
            input: value => {
                console.log({input: value})
                return value ? utcDatetimeToLocal(value) : null
            },
            output: value => {
                console.log({output: value})
                return localDateToUtc(value)
            }
        }}/>
    )
}
import { useGenericMutation, useMutationResult } from "@feg/ui/src";
import { FormErrorMessage } from "@feg/ui/src/common/form/FormErrorMessage";
import { FormStack } from "@feg/ui/src/common/form/FormStack";
import {
  CountryListQuery,
  UpsertCountryMutationVariables,
  useUpsertCountryMutation,
} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import { TextField } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../generic/buttons/SubmitButton";
import { BaseTranslationFormType } from "../../generic/validation/BaseTranslationSchema";
import { countriesEn } from "./world/countries-en";
import { countriesEs } from "./world/countries-es";
import { countriesFr } from "./world/countries-fr";
import { countriesPt } from "./world/countries-pt";

type CountryFormProps = {
  country?: CountryListQuery["country"][number];
};

export default function CountryForm({ country }: CountryFormProps) {
  const { t } = useTranslation();
  const { mutateAsync, isPending, data, error } = useUpsertCountryMutation();
  const { control, setValue, onSubmit } = useGenericMutation<
    BaseTranslationFormType,
    UpsertCountryMutationVariables,
    typeof mutateAsync
  >({
    id: country?.id,
    defaultValues: {
      en: country?.en,
      fr: country?.fr || "",
      pt: country?.pt || "",
      es: country?.es || "",
      key: country?.key || "",
    },
    mutateAsync,
    // GqlMutation: UpsertCountryDocument,
    gqlSchemaName: "insert_country_one",
  });
  useMutationResult({ data, error });

  return (
    <form noValidate onSubmit={onSubmit}>
      <FormStack>
        <FormErrorMessage control={control} />
        <TextField
          select
          required
          SelectProps={{
            native: true,
          }}
          defaultValue={country?.key}
          onChange={(ev) => {
            const id = ev.target.value;
            setValue("key", id);
            setValue(
              "en",
              countriesEn.find((i) => i.alpha2 === id)?.name || ""
            );
            setValue(
              "fr",
              countriesFr.find((i) => i.alpha2 === id)?.name || ""
            );
            setValue(
              "es",
              countriesEs.find((i) => i.alpha2 === id)?.name || ""
            );
            setValue(
              "pt",
              countriesPt.find((i) => i.alpha2 === id)?.name || ""
            );
          }}
        >
          <option></option>
          {countriesEn.map((c) => (
            <option key={c.alpha2} value={c.alpha2}>
              {c.name}
            </option>
          ))}
        </TextField>
        <TextFieldElement
          required
          name={"en"}
          label={t("layout.locale.en")}
          control={control}
        />
        <TextFieldElement
          name={"fr"}
          label={t("layout.locale.fr")}
          control={control}
        />
        <TextFieldElement
          name={"es"}
          label={t("layout.locale.es")}
          control={control}
        />
        <TextFieldElement
          name={"pt"}
          label={t("layout.locale.pt")}
          control={control}
        />
        <TextFieldElement
          name={"key"}
          label={t("country.form.code")}
          control={control}
        />
        <SubmitButton loading={isPending} />
      </FormStack>
    </form>
  );
}

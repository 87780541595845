import {Button, Card, CardContent, Stack, Typography} from "@mui/material";
import {useSignOut} from "@nhost/react";
import type {PropsWithChildren} from "react";
import {Link, useNavigate, useRouteError} from "react-router-dom";
import {SimpleBackgroundLayout} from "../SimpleBackgroundLayout";
import MuiLink from "@mui/material/Link";

export function NotPermitted() {
    return (
        <Layout>
            <Stack gap={2} alignItems={"center"} justifyContent={"center"}>
                <Typography variant={"h3"}>User is not permitted</Typography>
                <Typography variant={"body1"}>
                    You are not permitted to access this page.
                </Typography>
            </Stack>
        </Layout>
    );
}

export function ErrorComponent({error}: { error: any }) {
    return (
        <Layout>
            <Stack gap={2} alignItems={"center"} justifyContent={"center"}>
                <Typography variant={"h3"}>An error occurred</Typography>
                <Typography variant={"body1"}>
                    {error?.message || "You are not permitted to access this page."}
                </Typography>
            </Stack>
        </Layout>
    );
}

export function NotAuthenticated() {
    const {signOut} = useSignOut();
    const navigate = useNavigate();
    return (
        <Layout>
            <Stack gap={2} alignItems={"center"} justifyContent={"center"}>
                <Typography variant={"body1"}>
                    <Typography variant={"h3"}>You are not logged in.</Typography>
                    Please login in to access this page.
                </Typography>
                <Button
                    onClick={async () => {
                        await signOut();
                        navigate({pathname: "/auth/login"});
                    }}
                >
                    Login
                </Button>
            </Stack>
        </Layout>
    );
}

export function ErrorBoundary() {
    const error = useRouteError();
    console.log({error})
    return (
        <Layout>
            <Stack gap={2} alignItems={"center"} justifyContent={"center"}>
                <Typography variant={"h3"}>{error?.statusText || "An error occurred"}</Typography>
                <Typography variant={"body1"} maxWidth={400}>
                    {error?.data || "You are not permitted to access this page."}
                </Typography>
                <MuiLink
                    component={Link}
                    href="/"
                    color={"inherit"}
                >
                    Go back
                </MuiLink>
            </Stack>
        </Layout>
    );
}

function Layout({children}: PropsWithChildren) {
    return (
        <SimpleBackgroundLayout>
            <Card>
                <CardContent>{children}</CardContent>
            </Card>
        </SimpleBackgroundLayout>
    );
}

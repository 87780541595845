import { ConfirmButton } from "@feg/ui/src/common/buttons";
import { useSendVerificationEmail } from "@nhost/react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { UserOfList } from "./usersTypings";

export default function InviteUsersButton({ users }: { users: UserOfList[] }) {
  const [isFetching, setIsFetching] = useState<boolean>();
  const { sendEmail } = useSendVerificationEmail();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <ConfirmButton
      buttonProps={{
        color: "warning",
        loading: isFetching,
      }}
      onConfirm={async () => {
        setIsFetching(true);
        const redirectTo = `https://headigital.app`;
        const filteredUsers = users.filter((i) => !i.emailVerified);

        const promises = filteredUsers.map((user) => {
          return sendEmail(user.email, {
            redirectTo: redirectTo,
          });
        });
        const settled = await Promise.allSettled(promises);
        settled.forEach((res, index) => {
          console.log(res);
          if (
            res.status === "rejected" ||
            (res.status === "fulfilled" && res.value?.error)
          ) {
            enqueueSnackbar(
              `Failed to send email to ${filteredUsers[index]?.email}: ${
                res.status === "rejected"
                  ? res.reason
                  : res.value?.error?.message
              }`,
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar(`Email sent to ${filteredUsers[index]?.email}`, {
              variant: "success",
            });
          }
        });
        setIsFetching(false);
      }}
      message={`Do you want to invite ${users.length} user(s)?`}
    >
      INVITE
    </ConfirmButton>
  );
}

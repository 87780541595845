import {useTranslation} from "react-i18next";
import {useInterviewContext} from "../tss/InterviewProvider";
import {getProjectMonths} from "../lib";
import {MONTHS} from "./monthHelper";


export const useMonthOptions = (ns?: string) => {
    const ctx = useInterviewContext()
    const getter = ctx?.id && getProjectMonths(ctx.id)
    const monthsOpts = getter || MONTHS
    const {t} = useTranslation()
    return monthsOpts.map(k => {
        return {
            id: k,
            label: t(`month.${k}.short`, {ns})
        }
    });
}
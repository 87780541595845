import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import {TermListQuery, useGenericQuery} from '@feg/ui'
import {GridColDef} from '@mui/x-data-grid-pro'
import AddEditTermButton from './AddEditTermButton'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useDeleteTermMutation, useTermListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {useState} from "react";

const GridColums: GridColDef<TermListQuery['term'][number]>[] = [{
    field: 'en',
    headerName: 'English',
    flex: 1
}, {
    field: 'category_ids',
    headerName: 'Categories',
    valueGetter: (_, row) => row.term_category_mns?.map(i => i.term_category?.en).join(', ')
}, {
    field: 'fr',
    headerName: 'French',
    flex: 1
}, {
    field: 'es',
    headerName: 'Spanish',
    flex: 1
}, {
    field: 'pt',
    headerName: 'Portuguese',
    flex: 1
}, {
    field: 'am',
    headerName: 'Amharic',
    flex: 1
}, {
    field: 'kcal',
    headerName: 'Kcal',
    flex: 1
}]

export default function TermList() {
    const {data, refetch, error, isFetching} = useTermListQuery()
    const [selectedRows, setSelectedRows] = useState<TermListQuery['term']>([])

    useGenericQuery({
        refetch, isFetching, setSelectedRows
    })
    const {mutateAsync, isPending} = useDeleteTermMutation()
    const rows = data?.term || []
    return (
        <DataTableContainer
            ToolbarElements={
                <>
                    {selectedRows?.length === 1 && (
                        <AddEditTermButton term={selectedRows[0]}/>
                    )}
                    {selectedRows?.length > 0 && (
                        <DeleteTableRowButton
                            refetch={refetch}
                            mutateAsync={() => mutateAsync({
                                ids: selectedRows.map(i => i.id)
                            })} isLoading={isPending} count={selectedRows.length}/>
                    )}
                </>
            }
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}

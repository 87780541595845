import {GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton} from '@mui/x-data-grid-pro'
import {PropsWithChildren} from 'react'
import {Toolbar} from '@mui/material'

export function DataTableToolbar({children}: PropsWithChildren) {
    return (
        <Toolbar sx={{bgcolor: 'grey.200'}}>
            {children}
            <span style={{flexGrow: 1}}/>
            {/*<GridToolbarColumnsButton />*/}
            <GridToolbarFilterButton slotProps={{
                button: {
                    sx: {
                        mr: 2
                    }
                }
            }}/>
            <GridToolbarDensitySelector slotProps={{
                button: {
                    sx: {
                        mr: 2
                    }
                }
            }}/>
            <GridToolbarExport sx={{mr: 2}}/>
        </Toolbar>
    )
}

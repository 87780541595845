import {createContext, useContext} from "react";

export type InterviewProviderProps = {
    id: string
    projectId: string
    countryId?: string | null
}

export const InterviewContext = createContext<InterviewProviderProps>({id: '', projectId: '', countryId: null})


export const useInterviewContext = () => useContext(InterviewContext)

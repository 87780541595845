import {Card} from '@mui/material'
import {PropsWithChildren, ReactNode} from 'react'
import {DataGridPro as DataGrid, DataGridProProps as DataGridProps} from '@mui/x-data-grid-pro'
import {DataTableToolbar} from './DataTableToolbar'
import {GridValidRowModel} from "@mui/x-data-grid";

type DataTableContainerProps<R extends GridValidRowModel = any> = {
    ToolbarElements?: ReactNode
    dataTableProps: DataGridProps<R>
}

export default function DataTableContainer<R extends GridValidRowModel = any>({
                                                                                  children,
                                                                                  dataTableProps,
                                                                                  ToolbarElements
                                                                              }: PropsWithChildren<DataTableContainerProps<R>>) {
    return (
        <Card sx={{
            minHeight: 350
        }}>
            <div style={{height: 'calc(100vh - 240px)', width: '100%'}}>

                <DataGrid {...dataTableProps}
                          slots={{
                              toolbar: () => (
                                  <DataTableToolbar>{ToolbarElements}</DataTableToolbar>
                              )
                          }}/>
                {children}
            </div>
        </Card>
    )
}


import { NhostProvider } from "@nhost/react";
import { Outlet } from "react-router-dom";
import { AppSetupContainer } from "../common/layout/AppSetupContainer";
import { appNhostClient } from "../lib/nhost/appNhostClient";
import { NhostSimpleReactQueryProvider } from "../lib/nhost/NhostSimpleReactQueryProvider";

export function ViteAdminGlobalProvider() {
  // todo ContainerAuth needs to be handled differently
  // todo handle AllTssVariableSetup on per page basis?
  return (
    <NhostProvider nhost={appNhostClient}>
      <NhostSimpleReactQueryProvider>
        <AppSetupContainer>
          <Outlet />
        </AppSetupContainer>
      </NhostSimpleReactQueryProvider>
    </NhostProvider>
  );
}

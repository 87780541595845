import { LoadingButton } from "@mui/lab";
import { Alert, Box, BoxProps, Stack, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { useSignInEmailPassword } from "@nhost/react";
import { m } from "framer-motion";
import { ForwardRefExoticComponent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { varBounce } from "../../theme/components/animate/variants";

const schema = {
  email: "",
  password: "",
};
type User = typeof schema;

type LoginFormProps = {
  onSuccess: () => Promise<void>;
  needsEmailVerification?: boolean;
  containerProps?: BoxProps;
  LinkComponent?: ForwardRefExoticComponent<any>;
  linkHrefTo?: boolean;
};

export function LoginForm({
  onSuccess,
  containerProps,
  LinkComponent,
  linkHrefTo,
}: LoginFormProps) {
  const { t } = useTranslation();
  const {
    signInEmailPassword,
    error,
    isLoading,
    needsEmailVerification,
    isSuccess,
  } = useSignInEmailPassword();

  const { control, handleSubmit } = useForm<User>();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await signInEmailPassword(data.email, data.password);
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <Box gap={2} display={"flex"} flexDirection={"column"} {...containerProps}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          {t("auth.headlineLogin")}
        </Typography>
      </m.div>
      {needsEmailVerification && (
        <m.div variants={varBounce().in}>
          <Alert severity={"warning"}>
            We sent an email for verification to your email address. Please
            follow the instructions.
          </Alert>
        </m.div>
      )}
      {error && (
        <m.div variants={varBounce().in}>
          <Alert severity={"error"}>{error?.message}</Alert>
        </m.div>
      )}
      <form onSubmit={onSubmit} noValidate>
        <Stack direction={"column"} spacing={2}>
          <TextFieldElement
            name={"email"}
            label={t("auth.email")}
            control={control}
          />
          <PasswordElement
            name={"password"}
            label={t("auth.password")}
            control={control}
          />
          <LoadingButton type={"submit"} loading={isLoading}>
            {t("submit")}
          </LoadingButton>
        </Stack>
      </form>
      <Stack mt={3}>
        <MuiLink
          {...(LinkComponent && {
            component: LinkComponent,
          })}
          {...(linkHrefTo
            ? {
                to: "/auth/forgot-password",
              }
            : {
                href: "/auth/forgot-password",
              })}
        >
          {t("auth.forgotPassword")}
        </MuiLink>
      </Stack>
    </Box>
  );
}

import {NhostReactClientConstructorParams} from "@nhost/react";

type ConfigProps = {
    nhost: NhostReactClientConstructorParams
    tssDomain: string
    publicImageHost: string
};
const SHOW_PRODUCTION = process.env.NODE_ENV === 'production'
    || !!process.env.NEXT_PUBLIC_DATA_LOCATION
export const CONFIG: ConfigProps = {
    nhost: {
        subdomain: SHOW_PRODUCTION ? 'hjghcghdyiaisukunhfu' : 'local',
        region: SHOW_PRODUCTION ? 'eu-central-1' : 'local'
    },
    tssDomain: SHOW_PRODUCTION ? 'https://field.headigital.app' : 'http://localhost:3002',
    publicImageHost: "https://hjghcghdyiaisukunhfu.storage.eu-central-1.nhost.run/v1/files/"
};

export const UNDEFINED_UUID = '00000000-0000-0000-0000-000000000000';

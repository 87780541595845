import {PATH_DASHBOARD, ProjectFragment, ProjectListQuery, useGenericQuery,} from '@feg/ui'
import DataTableContainer from '../generic/dataTable/DataTableContainer'
import {GridColDef} from '@mui/x-data-grid-pro'
import {useCallback, useState} from 'react'
import ProjectInterviewList from './partials/ProjectInterviewList'
import {Box, Link as MuiLink} from '@mui/material'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {Link} from "react-router-dom";
import {useDeleteProjectMutation, useProjectListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

const DETAIL_HEIGHT = 250
const GridColums: GridColDef<ProjectListQuery['project'][number]>[] = [{
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params) => (
        <MuiLink
            component={Link}
            to={PATH_DASHBOARD.project.edit + params.id}
        >
            {params.value}
        </MuiLink>
    )
}, {
    field: 'project_interviews',
    headerName: 'Zone',
    flex: 1,
    valueGetter: (_, row) => {
        const zones: ProjectListQuery['project'][number]['project_interviews'][number]['location']['zone'][] = []
        row.project_interviews?.forEach((interview) => {
            const zone = interview.location?.zone
            if (!zones.some(i => i.id === zone.id)) {
                zones.push(zone)
            }
        })
        return zones.map(i => `${i.name} - ${i.country?.en}`).join(', ')
    }
}, {
    field: 'supervisor_user_id',
    headerName: 'Supervisor',
    flex: 0.4,
    valueGetter: (_, row) => row.user?.email
}]

type DetailPanelContentProps = {
    row: ProjectFragment
}

function DetailPanelContent({row}: DetailPanelContentProps) {
    return (
        <Box bgcolor={'grey.200'} height={DETAIL_HEIGHT} position={'relative'} style={{overflowY: 'auto'}}>
            <ProjectInterviewList interviews={row.project_interviews} projectId={row.id}/>
        </Box>
    )
}

export default function ProjectList() {
    const getDetailPanelContent = useCallback(
        ({row}: { row: any }) => <DetailPanelContent row={row}/>,
        []
    )
    const {isFetching, data, refetch} = useProjectListQuery()
    const [selectedRows, setSelectedRows] = useState<ProjectListQuery['project']>([])

    useGenericQuery({
        isFetching, refetch, setSelectedRows
    })
    const {isPending, mutateAsync} = useDeleteProjectMutation()
    const rows = data?.project || []
    return (
        <DataTableContainer
            ToolbarElements={
                <>
                    {selectedRows?.length > 0 && (
                        <DeleteTableRowButton
                            refetch={refetch}
                            mutateAsync={() => mutateAsync({
                                ids: selectedRows?.map(i => i.id)
                            })}
                            count={selectedRows?.length}
                            isLoading={isPending}
                        />
                    )}
                </>
            }
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                disableRowSelectionOnClick: true,
                getDetailPanelContent: getDetailPanelContent,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}

import {GridColDef} from '@mui/x-data-grid-pro'
import {MeasureListQuery, useGenericQuery} from '@feg/ui'
import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import AddEditMeasureButton from './AddEditMeasureButton'
import {useMemo, useState} from 'react'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useDeleteMeasureUnitMutation, useMeasureListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import i18n from "i18next";

const GridColums: GridColDef<MeasureListQuery['measure_unit'][number]>[] = [{
    field: 'name',
    headerName: 'Name',
    flex: 1
}, {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    valueGetter: (_, row) => i18n.t(`measureUnit.${row.type}`)
}, {
    field: 'multiplier',
    headerName: 'Conversion Multiplier',
    flex: 1
}, {
    field: 'country_id',
    headerName: 'Country',
    valueGetter: (_, row) => row.country?.en,
    flex: 0.5
}]


export default function MeasureList() {
    const {data, isFetching, refetch, error} = useMeasureListQuery()
    const [selectedRows, setSelectedRows] = useState<MeasureListQuery['measure_unit']>([])
    useGenericQuery({
        refetch, isFetching, setSelectedRows
    })
    const {mutateAsync, isPending} = useDeleteMeasureUnitMutation()
    const ToolbarEls = useMemo(() => {
        return (
            <>
                {selectedRows?.length === 1 && (
                    <AddEditMeasureButton measureUnit={selectedRows[0]}/>
                )}
                {selectedRows?.length > 0 && (
                    <DeleteTableRowButton
                        refetch={refetch}
                        count={selectedRows.length} isLoading={isPending}
                        mutateAsync={() => mutateAsync({
                            ids: selectedRows.map(i => i.id)
                        })}/>
                )}
            </>
        )
    }, [selectedRows])
    const rows = data?.measure_unit || []
    return (
        <DataTableContainer
            ToolbarElements={ToolbarEls}
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}

import {MultiSelectElement, MultiSelectElementProps} from 'react-hook-form-mui'
import {useTranslation} from 'react-i18next'
import {FieldValues, useFormContext} from 'react-hook-form'
import {useMonthOptions} from "../../hooks/useMonthOptions";
import {IconButton, Stack} from "@mui/material";
import {EventAvailable, EventBusy} from "@mui/icons-material";
import {useState} from "react";


type MonthSelectElementProps<T extends FieldValues> = Omit<MultiSelectElementProps<T>, 'options' | 'ref'> & {
    ns?: string
    hideMultiAdd?: boolean
    showAllOptions?: boolean
}

export function MonthMultiSelectElement<TFieldValues extends FieldValues>({
                                                                              ns,
                                                                              hideMultiAdd,
                                                                              showAllOptions,
                                                                              ...props
                                                                          }: MonthSelectElementProps<TFieldValues>) {
    const {t} = useTranslation()
    const [all, setAll] = useState(false)
    const options = useMonthOptions(ns)
    const {setValue} = useFormContext() || {}
    return (
        <Stack direction={'row'} spacing={1}>
            <MultiSelectElement {...props}
                                sx={{minWidth: '120px'}}
                                renderValue={(value: any) => {
                                    if (!showAllOptions && value?.length > 2) {
                                        return value.length === 12 ? t('allYear') : t('monthsSelected', {number: value.length})
                                    }
                                    return options
                                        .filter(i => value.find((j: any) => j === i.id))
                                        .map((v) => t(`month.${v.id}.short`, {ns})).join(', ')
                                }}
                                MenuProps={
                                    {
                                        PaperProps: {
                                            sx: {
                                                '& .MuiList-root': {
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(3, 1fr)',
                                                    gap: '4px'
                                                }
                                            }
                                        }
                                    }
                                }
                                options={options}/>
            {!hideMultiAdd && (
                <IconButton
                    size={'small'}
                    onClick={() => {
                        if (!all) {
                            setValue(props.name, options.map(i => i.id) as any)
                            setAll(true)
                        } else {
                            setValue(props.name, [] as any)
                            setAll(false)
                        }
                    }}>
                    {!all ? <EventAvailable/> : <EventBusy/>}
                </IconButton>
            )}
        </Stack>
    )
}

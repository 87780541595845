import {
  TermCategoryListQuery,
  TermListQuery,
  UpsertTermMutationVariables,
  useGenericMutation,
  useMutationResult,
} from "@feg/ui";
import { useTranslation } from "react-i18next";
import { translationDefaultSchema } from "../../generic/validation/BaseTranslationSchema";

import { AutocompleteFetchQuery } from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import { FormStack } from "@feg/ui/src/common/form/FormStack";
import { TextFieldNumberFormat } from "@feg/ui/src/common/form/TextFieldNumberFormat";
import { UNDEFINED_UUID } from "@feg/ui/src/lib/config";
import {
  useTermCategoryListQuery,
  useUpsertTermMutation,
} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import { TextFieldElement } from "react-hook-form-mui";
import SubmitButton from "../../generic/buttons/SubmitButton";

const schema = {
  ...translationDefaultSchema,
  kcal: 0,
  category_ids: [],
};

type Schema = typeof schema;

type TermFormProps = {
  term?: TermListQuery["term"][number];
};

export default function TermForm({ term }: TermFormProps) {
  const { t } = useTranslation();
  const {
    isPending: isFetching,
    data,
    error,
    mutateAsync,
  } = useUpsertTermMutation();
  const { control, onSubmit } = useGenericMutation<
    Schema,
    UpsertTermMutationVariables,
    typeof mutateAsync
  >({
    id: term?.id,
    mutateAsync,
    gqlSchemaName: "insert_term_one",
    defaultValues: {
      en: term?.en,
      fr: term?.fr || "",
      pt: term?.pt || "",
      es: term?.es || "",
      am: term?.am || "",
      kcal: term?.kcal || 0,
      category_ids: term?.term_category_mns?.map((i) => i.category_id) ?? [],
    },
    prepareFormData: (data, id) => {
      return {
        object: {
          id,
          en: data.en,
          fr: data.fr,
          es: data.es,
          pt: data.pt,
          am: data.am,
          kcal: data.kcal ? Number(data.kcal) : null,
          ...(!id && {
            term_category_mns: {
              data: data.category_ids.map((cat_id) => ({
                category_id: cat_id,
              })),
            },
          }),
        },
        termId: id || UNDEFINED_UUID, // should not match any termId
        mnObjects: id
          ? data.category_ids.map((cat_id) => ({
              category_id: cat_id,
              term_id: id,
            }))
          : [],
      };
    },
  });
  useMutationResult({ data, error });
  return (
    <form noValidate onSubmit={onSubmit}>
      <FormStack>
        <TextFieldElement
          name={"en"}
          label={t("layout.locale.en")}
          control={control}
          required
        />
        <AutocompleteFetchQuery
          name={"category_ids"}
          asyncQuery={useTermCategoryListQuery.fetcher()}
          gqlSchema={"term_category"}
          autocompleteProps={{
            multiple: true,
            getOptionLabel: (
              option: TermCategoryListQuery["term_category"][number] | string
            ) => {
              if (typeof option === "string") {
                return option;
              }
              if (!option) {
                return "";
              }
              return option?.en;
            },
          }}
          matchId={true}
          control={control}
        />

        <TextFieldElement
          name={"fr"}
          label={t("layout.locale.fr")}
          control={control}
        />
        <TextFieldElement
          name={"es"}
          label={t("layout.locale.es")}
          control={control}
        />
        <TextFieldElement
          name={"pt"}
          label={t("layout.locale.pt")}
          control={control}
        />
        <TextFieldElement
          name={"am"}
          label={t("layout.locale.am")}
          control={control}
        />
        <TextFieldNumberFormat
          name={"kcal"}
          label={t("kcal")}
          control={control as any}
        />
        <SubmitButton loading={isFetching} />
      </FormStack>
    </form>
  );
}

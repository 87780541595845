import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import Login from "@feg/ui/src/vite/auth/Login";
import EmailVerified from "@feg/ui/src/vite/auth/EmailVerified";
import ForgotPassword from "@feg/ui/src/vite/auth/ForgotPassword";
import ChangePassword from "@feg/ui/src/vite/auth/ChangePassword";
import LandingPage from "./pages/LandingPage";
import {ViteAdminGlobalProvider} from "@feg/ui/src/vite/ViteAdminGlobalProvider";
import {AdminDashboardLayout} from "@feg/ui/src/theme/layouts/dashboard/AdminDashboardLayout";
import {PATH_DASHBOARD} from "@feg/ui";
import TermCategoryPage from "./pages/dashboard/admin/category-terms";
import CropSeasonsPage from "./pages/dashboard/admin/crop-seasons";
import MeasurePage from "./pages/dashboard/admin/measure";
import TermPage from "./pages/dashboard/admin/term";
import WealthGroupPage from "./pages/dashboard/admin/wealth-group";
import LocationPage from "./pages/dashboard/location/list";
import CountryPage from "./pages/dashboard/location/country";
import RegionPage from "./pages/dashboard/location/region";
import PopulationPage from "./pages/dashboard/location/population";
import ZonePage from "./pages/dashboard/location/zone";
import UserListPage from "./pages/dashboard/user/list";
import ProjectsPage from "./pages/dashboard/projects/Projects";
import ProjectEditPage from "./pages/dashboard/projects/ProjectEdit";
import InterviewEditPage from "./pages/dashboard/interviews/InterviewEdit";
import {ErrorBoundary} from "@feg/ui/src/common/layout/errorPages/ErrorComponent";


const tssReactRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<ViteAdminGlobalProvider/>} errorElement={<ErrorBoundary/>}>
            <Route element={<AdminDashboardLayout/>}>

                <Route index element={<LandingPage/>}/>
                <Route path={PATH_DASHBOARD.general.app} element={<LandingPage/>}/>


                <Route path={PATH_DASHBOARD.user.list} element={<UserListPage/>}/>

                <Route path={PATH_DASHBOARD.project.view} element={<ProjectsPage/>}/>
                <Route path={PATH_DASHBOARD.project.edit}>
                    <Route path={':id'} element={<ProjectEditPage/>}/>
                </Route>
                <Route path={PATH_DASHBOARD.interview.edit}>
                    <Route path={':id'} element={<InterviewEditPage/>}/>
                </Route>

                <Route path={PATH_DASHBOARD.location.list} element={<LocationPage/>}/>
                <Route path={PATH_DASHBOARD.location.countryList} element={<CountryPage/>}/>
                <Route path={PATH_DASHBOARD.location.countryRegion} element={<RegionPage/>}/>
                <Route path={PATH_DASHBOARD.location.population} element={<PopulationPage/>}/>
                <Route path={PATH_DASHBOARD.location.zoneList} element={<ZonePage/>}/>

                <Route path={PATH_DASHBOARD.admin.termCategory} element={<TermCategoryPage/>}/>
                <Route path={PATH_DASHBOARD.admin.crops} element={<CropSeasonsPage/>}/>
                <Route path={PATH_DASHBOARD.admin.measure} element={<MeasurePage/>}/>
                <Route path={PATH_DASHBOARD.admin.term} element={<TermPage/>}/>
                <Route path={PATH_DASHBOARD.admin.wealthGroupList} element={<WealthGroupPage/>}/>

            </Route>
            <Route path={'/auth'}>
                <Route path={'login'} element={<Login/>}/>
                <Route path={'email-verified'} element={<EmailVerified/>}/>
                <Route path={'forgot-password'} element={<ForgotPassword/>}/>
                <Route path={'change-password'} element={<ChangePassword/>}/>
            </Route>
        </Route>
    )
);

export const FegReactRouter = () => (
    <RouterProvider router={tssReactRouter}/>
)
import { LoadingButton } from "@mui/lab";
import { Alert, Box, BoxProps, Stack, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { useResetPassword, useSendVerificationEmail } from "@nhost/react";
import { m } from "framer-motion";
import { ForwardRefExoticComponent } from "react";
import { useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { varBounce } from "../../theme/components/animate/variants";

const schema = {
  email: "",
};
type User = typeof schema;

type PasswordForgetFormProps = {
  onSuccess?: () => Promise<void>;
  containerProps?: BoxProps;
  LinkComponent?: ForwardRefExoticComponent<any>;
  linkHrefTo?: boolean;
};

export function PasswordForgetForm({
  onSuccess,
  containerProps,
  LinkComponent,
  linkHrefTo,
}: PasswordForgetFormProps) {
  const { t } = useTranslation();
  const { resetPassword, error, isLoading, isSent } = useResetPassword();
  const {
    sendEmail,
    isLoading: isLoadingVerify,
    isSent: isSentVerify,
  } = useSendVerificationEmail();
  const { control, handleSubmit } = useForm<User>();
  const onSubmit = handleSubmit(async (data) => {
    const res = await resetPassword(data.email, {
      redirectTo: `${window.location.origin}/auth/change-password`,
    });
    if (res.error) {
      if (res.error.error === "unverified-user") {
        await sendEmail(data.email, {
          redirectTo: `${window.location.origin}/auth/change-password`,
        });
      }
    }
    if (typeof onSuccess === "function") {
      await onSuccess();
    }
  });

  return (
    <Box {...containerProps}>
      <m.div variants={varBounce().in}>
        <Typography variant="h5" margin={2} paragraph>
          {t("auth.headlineForgotPassword")}
        </Typography>
      </m.div>
      {error && (
        <m.div variants={varBounce().in}>
          <Alert severity={"error"} sx={{ marginY: 3 }}>
            {error?.message}
          </Alert>
        </m.div>
      )}
      {isSentVerify && (
        <m.div variants={varBounce().in}>
          <Alert severity={"info"} sx={{ marginY: 3 }}>
            {t("auth.verifyEmailMessage")}
          </Alert>
        </m.div>
      )}
      {isSent && (
        <m.div variants={varBounce().in}>
          <Alert severity={"success"}>{t("auth.passwordResetMessage")}</Alert>
        </m.div>
      )}
      {!isSent && (
        <form onSubmit={onSubmit} noValidate>
          <Stack direction={"column"} spacing={2}>
            <TextFieldElement
              name={"email"}
              label={t("auth.email")}
              control={control}
            />
            <LoadingButton
              type={"submit"}
              loading={isLoading || isLoadingVerify}
            >
              {t("submit")}
            </LoadingButton>
            <MuiLink
              {...(LinkComponent && {
                component: LinkComponent,
              })}
              {...(linkHrefTo
                ? {
                    to: "/auth/login",
                  }
                : {
                    href: "/auth/login",
                  })}
            >
              {t("auth.backToLogin")}
            </MuiLink>
          </Stack>
        </form>
      )}
    </Box>
  );
}

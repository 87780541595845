import {
  CountryListQuery,
  UpsertZoneMutationVariables,
  useGenericMutation,
  useMutationResult,
  ZoneListQuery,
} from "@feg/ui";
import { AutocompleteFetchQuery } from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import { FormErrorMessage } from "@feg/ui/src/common/form/FormErrorMessage";
import { FormStack } from "@feg/ui/src/common/form/FormStack";
import {
  useCountryListQuery,
  useUpsertZoneMutation,
} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import { TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../generic/buttons/SubmitButton";

const schema = {
  name: "",
  country_id: "",
};
type Schema = typeof schema;

type ZoneFormProps = {
  zone?: ZoneListQuery["zone"][number];
};

export default function ZoneForm({ zone }: ZoneFormProps) {
  const { t } = useTranslation();
  const { data, error, isPending, mutateAsync } = useUpsertZoneMutation();
  const { control, onSubmit } = useGenericMutation<
    Schema,
    UpsertZoneMutationVariables,
    typeof mutateAsync
  >({
    id: zone?.id,
    mutateAsync,
    defaultValues: {
      name: zone?.name || "",
      country_id: zone?.country_id || "",
    },
    gqlSchemaName: "insert_zone",
    prepareFormData: (data) => {
      const nameArray = data.name.split(",").map((k) => k.trim());
      return {
        objects: nameArray.map((name) => ({
          id: zone?.id,
          name,
          country_id: data.country_id,
        })),
      };
    },
  });
  useMutationResult({ data, error });

  return (
    <form noValidate onSubmit={onSubmit}>
      <FormStack>
        <FormErrorMessage control={control} />
        <TextFieldElement
          required
          name={"name"}
          {...(!zone?.id && {
            multiline: true,
            minRows: 3,
            helperText: t("zone.form.nameHelper"),
          })}
          label={t("zone.form.name")}
          control={control}
        />
        <AutocompleteFetchQuery
          name={"country_id"}
          label={t("country.name")}
          queryKey={useCountryListQuery.getKey()}
          asyncQuery={useCountryListQuery.fetcher()}
          gqlSchema={"country"}
          required
          matchId
          control={control}
          autocompleteProps={{
            getOptionLabel: (
              option: CountryListQuery["country"][number] | string
            ) => {
              if (typeof option === "string") return option;
              if (!option) return "";
              return option.en;
            },
          }}
        />
        <SubmitButton loading={isPending} />
      </FormStack>
    </form>
  );
}

import {Month_Enum} from "../lib/gql/__codegen__/react-query"


export const MONTHS = [Month_Enum.January, Month_Enum.February, Month_Enum.March, Month_Enum.April, Month_Enum.May, Month_Enum.June, Month_Enum.July, Month_Enum.August, Month_Enum.September, Month_Enum.October, Month_Enum.November, Month_Enum.December]

export const getMonthIndex = (month: Month_Enum) => MONTHS.indexOf(month)

export const getMonthsOnDays = (startMonth?: Month_Enum, days?: number, total?: number) => {
    if (!(startMonth && days && total)) {
        return null
    }
    const numberMonth = Math.round(Number(days) / 30)
    const eachTotal = Number(total) / numberMonth
    const startIndex = getMonthIndex(startMonth)
    const requestedMonths: { month: Month_Enum, value: number }[] = []
    for (let start = 0; start < numberMonth; start++) {
        requestedMonths.push({
            month: MONTHS[(startIndex + start) % 12],
            value: eachTotal
        })
    }
    const endMonth = requestedMonths[requestedMonths.length - 1]?.month;
    return {
        months: requestedMonths,
        endMonth: endMonth,
        nextMonth: MONTHS[(getMonthIndex(endMonth) + 1) % 12]
    }
}
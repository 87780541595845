import {PropsWithChildren} from 'react'
import {styled} from '@mui/material/styles'
import {Box, Container, Stack} from '@mui/material'
// @ts-ignore
import bgImage from './agriculture.jpg'
// @ts-ignore
import logoImage from './hea_digital_dark.jpeg'
import {Page} from "../../theme/components/Page";
import MotionContainer from '../../theme/components/animate/MotionContainer';

const ENABLE_VITE = !!process.env.VITE_ENABLE_VITE


const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    height: '100vh',
    position: 'relative',
    alignItems: 'center',
    // paddingTop: theme.spacing(15),
    // paddingBottom: theme.spacing(10)
}))

type PageRootSimpleProps = {
    title: string
}

export function PageRootSimpleSpa({children, title}: PropsWithChildren<PageRootSimpleProps>) {
    return (
        <Page title={title} sx={{position: 'relative', height: '100vh'}}>
            <img src={bgImage as any} alt={'headigital'}
                 style={{
                     position: 'absolute',
                     width: '100%',
                     height: '100%',
                     objectFit: 'cover'
                 }}/>
            <RootStyle>
                <Container component={MotionContainer}>
                    <Box sx={{
                        maxWidth: 480,
                        margin: 'auto',
                        textAlign: 'center',
                        padding: 3,
                        borderRadius: '4px',
                        bgcolor: 'rgba(255,255,255,0.7)'
                    }}>
                        <Stack direction={'row'} justifyContent={'center'}>
                            {/* eslint-disable-next-line @next/next/no-img-element */}
                            <img src={logoImage as any} alt={'headigital'}/>
                        </Stack>
                        {children}
                    </Box>
                </Container>
            </RootStyle>
        </Page>
    )
}


import {fromZonedTime, toZonedTime} from "date-fns-tz"
import {format} from "date-fns";

export const userTimeZone =
    process.env.NODE_ENV === "test"
        ? "Asia/Makassar"
        : Intl.DateTimeFormat().resolvedOptions().timeZone


export function localDateToUtc(
    localDate: Date,
    options?: {
        timeZone?: string
    }
): string
export function localDateToUtc(
    localDate?: undefined,
    options?: {
        timeZone?: string
    }
): undefined
export function localDateToUtc(
    localDate?: Date,
    options?: {
        timeZone?: string
    }
): string | null {
    if (localDate) {
        const utcDate = fromZonedTime(localDate, options?.timeZone || userTimeZone)
        // const utcDate = transpose(localDate, tz(options?.timeZone || userTimeZone))
        return format(utcDate, "yyyy-MM-dd")
    }
    return null
}

export const localDatetimeToUtc = (
    localDate: Date | string,
    options?: {
        timeZone?: string
    }
): string => {
    const utcDate = fromZonedTime(localDate, options?.timeZone || userTimeZone)

    return utcDate.toISOString()
}

export const utcDatetimeToLocal = (
    isoDate: Date | string,
    options?: { timeZone?: string }
): Date => {
    return toZonedTime(isoDate, options?.timeZone || userTimeZone)
}

export function utcDateToLocal(isoDate: string): Date
export function utcDateToLocal(isoDate?: undefined): undefined
export function utcDateToLocal(isoDate?: string): Date | undefined {
    if (!isoDate) return
    const [year, month, day] = isoDate.split("-").map(Number)
    return new Date(year, month - 1, day)
}

import {GridColDef} from '@mui/x-data-grid-pro'
import {CropSeasonListQuery, useGenericQuery} from '@feg/ui'
import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import AddEditCropSeasonButton from './AddEditCropSeasonButton'
import {useMemo, useState} from 'react'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useCropSeasonListQuery, useDeleteCropSeasonMutation} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import i18n from "i18next";

const GridColums: GridColDef<CropSeasonListQuery['crop_season'][number]>[] = [{
    field: 'name',
    headerName: 'Name',
}, {
    field: 'country_id',
    headerName: 'Country',
    valueGetter: (_, row) => row.country?.en,

}, {
    field: 'Months',
    valueGetter: (_, row) => row.crop_season_month_mns.map(mn => i18n.t(`month.${mn.month}.short`)).join(', '),
    flex: 1
}]


export default function CropSeasonList() {
    const {data, refetch, error, isFetching} = useCropSeasonListQuery()
    const [selectedRows, setSelectedRows] = useState<CropSeasonListQuery['crop_season']>([])
    useGenericQuery({
        refetch, setSelectedRows, isFetching
    })
    const {mutateAsync, isPending} = useDeleteCropSeasonMutation()
    const ToolbarEls = useMemo(() => {
        return (
            <>
                {selectedRows?.length === 1 && (
                    <AddEditCropSeasonButton cropSeason={selectedRows[0]}/>
                )}
                {selectedRows?.length > 0 && (
                    <DeleteTableRowButton
                        refetch={refetch}
                        count={selectedRows.length}
                        isLoading={isPending}
                        mutateAsync={() => mutateAsync({
                            ids: selectedRows.map(i => i.id)
                        })}
                    />
                )}
            </>
        )
    }, [selectedRows])
    const rows = data?.crop_season || []
    return (
        <DataTableContainer
            ToolbarElements={ToolbarEls}
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}

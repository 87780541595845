import {
  CountryListQuery,
  Crop_Season_Month_Mn_Constraint,
  CropSeasonListQuery,
  Month_Enum,
  UpsertCropSeasonMutationVariables,
  useGenericMutation,
  useMutationResult,
} from "@feg/ui";
import { AutocompleteFetchQuery } from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import { FormStack } from "@feg/ui/src/common/form/FormStack";
import { MonthMultiSelectElement } from "@feg/ui/src/common/form/MonthMultiSelectElement";
import { UNDEFINED_UUID } from "@feg/ui/src/lib/config";
import {
  useCountryListQuery,
  useUpsertCropSeasonMutation,
} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import { TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../generic/buttons/SubmitButton";

const schema = {
  name: "",
  country_id: [],
  month_ids: [],
};

type Schema = typeof schema;

type CropSeasonFormProps = {
  cropSeason?: CropSeasonListQuery["crop_season"][number];
};

export default function CropSeasonForm({ cropSeason }: CropSeasonFormProps) {
  const { t } = useTranslation();
  const {
    mutateAsync,
    isPending: isFetching,
    data,
    error,
  } = useUpsertCropSeasonMutation();
  const { control, onSubmit } = useGenericMutation<
    Schema,
    UpsertCropSeasonMutationVariables,
    typeof mutateAsync
  >({
    id: cropSeason?.id,
    gqlSchemaName: "insert_crop_season_one",
    mutateAsync,
    defaultValues: {
      name: cropSeason?.name,
      country_id: cropSeason?.country_id,
    },
    prepareFormData: (data, id) => {
      const monthIds = data.month_ids ?? [];
      const upsert: UpsertCropSeasonMutationVariables = {
        object: {
          id,
          name: data.name,
          country_id: data.country_id,
          ...(!id && {
            crop_season_month_mns: {
              data: monthIds?.map((monthId) => ({
                month: monthId as Month_Enum,
              })),
              on_conflict: {
                constraint:
                  Crop_Season_Month_Mn_Constraint.CropSeasonMonthMnCropSeasonIdMonthKey,
              },
            },
          }),
        },
        seasonId: (id as string) || UNDEFINED_UUID,
        mnObjects: !id
          ? []
          : monthIds?.map((monthId) => ({
              month: monthId as Month_Enum,
              crop_season_id: id,
            })),
      };
      return upsert;
    },
  });
  useMutationResult({ data, error });

  return (
    <form noValidate onSubmit={onSubmit}>
      <FormStack>
        <TextFieldElement
          name={"name"}
          label={t("crops.form.name")}
          control={control}
          required
        />
        <AutocompleteFetchQuery
          name={"country_id"}
          label={t("country.name")}
          queryKey={useCountryListQuery.getKey()}
          asyncQuery={useCountryListQuery.fetcher()}
          gqlSchema={"country"}
          required
          matchId
          control={control}
          autocompleteProps={{
            getOptionLabel: (
              option: CountryListQuery["country"][number] | string
            ) => {
              if (typeof option === "string") return option;
              if (!option) return "";
              return option.en;
            },
          }}
        />
        <MonthMultiSelectElement
          name={"month_ids"}
          label={t("months")}
          control={control}
          fullWidth
          showAllOptions
          hideMultiAdd={true}
        />
        <SubmitButton loading={isFetching} />
      </FormStack>
    </form>
  );
}

import {CONFIG} from "../../lib/config";
import {Box, Stack} from "@mui/material";
import {PropsWithChildren} from "react";

export function SimpleBackgroundLayout({children}: PropsWithChildren) {
    return (
        <Box height={'100vh'} width={'100vw'}>
            <img src={`${CONFIG.publicImageHost}7a529215-b2ba-4c88-9885-649fcc490b3c`} style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: -1
            }}/>
            <Stack display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}
                   height={'100%'} width={'100%'} gap={4}>
                {children}
            </Stack>
        </Box>
    )
}
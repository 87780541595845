import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { GuardAdmin } from "../../../vite/GuardAdmin";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import { MainStyle, RootStyle } from "./RootStyle";
import DashboardHeader from "./header";
import DashboardNavbar from "./navbar";

export function AdminDashboardLayout() {
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <GuardAdmin>
      <RootStyle>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          LinkComponent={Link}
        />

        <DashboardNavbar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />

        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </GuardAdmin>
  );
}
